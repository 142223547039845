/*predefine colors*/

/*
:root {
  --light-background: #FAFAFA;
  --light-element-background: #FFFFFF;
  --light-theme-color: #835E78;
  --light-text: #333333;
  --light-gray: #C6C6C6;
  
  --light-red: #ab182d;
  --light-red-medium: #ea6c7e;
  --light-red-background: #fceef0;
  --light-green: #57B33E;
  --light-green-medium: #7fea6c;
  --light-green-background: #f0fcee;
  --light-orange: #EF7D00;
  --light-orange-medium: #ffba6f;
  --light-orange-background: #fff7ee;
  --light-blue: #27348b;
  --light-blue-background: #eff0fa;
  --light-blue-medium: #7582d8;
  --light-group: #9d9694;
  --light-group-background: #f9f8f8;
  --light-group-medium: #cbc7c6;
  
  --light-teams: #5558af;
  --light-facebook: #3b5998;
  --light-twitter: #1da1f2;
  --light-linkedin: #0077b5;
  --light-instagram: #e1306c;
  --light-youtube: #ff0000;
  
  --dark-background: #1A1217;
  --dark-element-background: #271B23;
  --dark-theme-color: #412F3B;
  --dark-text: #CFCACD;
  --dark-gray: #C6C6C6;
  
  --dark-red: #660e1b;
  --dark-red-medium: #4c0a14;
  --dark-red-background: #29070c;
  --dark-green: #1b660e;
  --dark-green-medium: #144c0a;
  --dark-green-background: #0c2907;
  --dark-orange: #8f4b00;
  --dark-orange-medium: #6b3800;
  --dark-orange-background: #311a00;
  --dark-blue: #2e3da4;
  --dark-blue-background: #0b0d25;
  --dark-blue-medium: #1d2668;
  --dark-group: #4f4a48;
  --dark-group-background: #f9f8f8;
  --dark-group-medium: #cbc7c6;
  
  --dark-teams: darken(--light-teams, 15);
  --dark-facebook: darken(--light-facebook, 15);
  --dark-twitter: darken(--light-twitter, 25);
  --dark-linkedin: darken(--light-linkedin, 15);
  --dark-instagram: darken(--light-instagram, 25);
  --dark-youtube: darken(--light-youtube, 25);
  
  --black: #333333;
  --white: #FFFFFF;



}*/



:root {
  --theme-color: #9d9694;
  --background-color: #FAFAFA;
  --background-color-trans: #FAFAFA99;
  --background-color-dark: #FAFAFA;
  --block-background: #FFFFFF;
  --second-block-background: #9d9694;
  --accent-line-color: #660e1bff;
  --text-color: #8f8f8f;
  --text-color-trans: rgb(112, 112, 112);
  --color: #333333;
  --light-background: #fafafa;
}

[data-theme="dark"] {
  --theme-color: #412F3B;
  --background-color: #1a1217ff;
  --background-color--trans: #1a121766;
  --background-color-dark: #1A1217;
  --block-background: #271b23ff;
  --second-block-background: #412f3bff;
  --accent-line-color: #660e1bff;
  --text-color: #898186ff;
  --text-color-trans: #5c324c85;
  --color: #898186ff;
  --light-background: #1a1217ff;
}



/*end predefine colors*/
@font-face {
  font-family: "montserat";
  src: local("montserat"),
    url("./Fonts/Montserrat-VariableFont_wght.ttf") format("truetype")
}




head,
body {
  width: 100%;
  height: 100%;
  font-family: "montserat", Verdana, Geneva, Tahoma, sans-serif;
  overflow-x: hidden;
  background-color: #eae6e6;
  transition: .8s;
  background-color: var(--light-background);

  color: var(--color);

}

.content {
  scroll-snap-type: x mandatory;
  width: 100%;
}

section {
  scroll-snap-align: start;
}


.dark-mode {
  background-color: black;
  color: white;
  transition: .8s;
  background-color: var(--black);
}

h1 i {

  display: inline-block;
  border-radius: 50%;
  border: 2px solid;
  width: 1em;
  height: 1em;
  padding: 0.3em;
  margin: 0.3em;
  font-size: 0.9em;
  color: inherit;
}

a {
  color: var(--theme-color);
  overflow-wrap: break-word}

a:hover {
  color: #ef7d00;
}

.person {
  display: none;

}

.person:hover {
  background-color: transparent;

}

.navelement {
  position: inherit;
}


.container {
  width: 100%;
  height: 100%;
  margin: 0em auto;
  padding-top: 3em;
  display: flex;
  background-color: #eae6e6;
  background-color: var(--light-element-background);
  color: var(--color);


}

.content-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto auto;
  padding-bottom: 3em;
}

.nav-stepper {
  display: flex;
  flex-wrap: wrap;
}

.nav-stepper__item {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.nav-stepper__circle {
  --size: 3rem;
  position: relative;
  z-index: 1;
  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  margin: 0 auto;
  background-color: var(--background-color);
}

.nav-stepper__circle i {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-stepper__title {

  font-size: 16px;
  color: inherit;
}


.nav-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: 1.5rem;
  left: 50%;
  height: 2px;
  background-color: #e0e0e0;
  order: -1;
}

.nav-stepper a {
  float: left;
  text-align: center;
  padding: 14px 0;
  text-decoration: none;
  font-size: 1.2em;
  color: #fff;
}

.nav-stepper a i {
  color: var(--color);
}

.nav-stepper a:hover {
  color: var(--color);
}

.topnav {
  overflow: hidden;
  background-color: var(--second-block-background);
  color: #fff;
}

#topnavigation {
  margin-top: 2em;
}

#toplogo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1em 4em;
  margin-bottom: 0;
}

#toplogo img {
  width: 15em;
}

.intro {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  background-image: url("./img/webp/intro.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #c4b0a470;

  display: block;
  float: left;
  width: 100%;
}


.introText {
  display: block;
  width: 30em;
  text-align: right;
  margin-right: 8em;
  text-align: center;
}

.intro .introIcon {
  display: block;
  float: right;
  width: 30%;
  max-width: 300px;
  min-width: 160px;
}


.intro h1,
.intro p {
  color: var(--text-color);
  padding: 10px;
  margin: 0px;
  font-weight: 600;
}

.intro .introimg {
  display: block;
  float: right;
  width: 90%;
  margin-top: -40px;
  margin-right: 20px;
}



.hsliderframe {
  width: 100%;
  height: 320px;
  overflow: hidden;
  overflow-x: scroll;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #666666;
  padding: 15px;
  color: #cccccc;
  min-height: 300px;
  position: absolute;
}


.hslidercontent {
  width: auto;
  height: 100%;
  height: calc(110% + 2em);
  color: #000000;
  position: absolute;
  float: none;
  display: flex;

}

.hsliderblock {
  display: block;
  float: left;
  padding: 15px;
  background-color: #cccccc;
  border-radius: 10px;
  -webkit-box-shadow: 1px 7px 20px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 20px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 7px 20px -11px rgba(0, 0, 0, 0.75);
  margin-left: 10%;
  width: 80%;
  height: calc(100% - 4em);
  margin-right: 10%;
  margin-top: 10px;
}

.slidernext {

  display: block;
  padding: 1em;
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  font-size: 1em;
  width: 1em;
  height: 1em;
  position: relative;

  left: 100%;
  top: -25%;

  background-color: #ffffff;
  border-radius: 50%;




}


.footer {
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
}

.carouselIntro {
  display: flex;
  padding: 20px;
  color:
}

.carouselIntro h1 {

  display: block;
  float: left;
  padding: 5px;
}


.carouselIntro p {

  display: block;
  float: left;
  padding: 2px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* Hide the scrollbar for MS Edge */
  scrollbar-width: none;
  /* Hide the scrollbar for Mozilla Firefox */
  margin: 0 auto 0 auto;
  width: 100%;
  height: fit-content;
  position: relative;

  overflow-y: scroll;
  scroll-snap-type: y mandatory;



  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */



}

.carousel::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar on Webkit based browsers (Chrome, Safari, etc) */
  -webkit-overflow-scrolling: touch;
  /* On touch screens the content continues to scroll for a while after finishing the scroll gesture */
}

.contentblock {
  width: 100%;
  display: flex;
  float: left;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  background-color: var(--second-block-background);
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: inset 0 0 10px #70707025;
  font-weight: 600;
}


.posindicator {
  width: 100%;
  display: flexbox;
  float: left;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  background-color: var(--second-block-background);
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: inset 0 0 10px #70707025;
}

.slider {
  width: 100%;
}



.fullwidth {
  width: 100%;

}

.uploader {

  width: 100%;
  display: block;
  margin-top: 20px;
}


.collapsecontainer {
  display: block;
  float: left;
  width: 100%;
  clear: both;
  background-color: red;


}

.collapsetoggle {
  display: block;
  background-color: #ffcc00;

}

.hiddencollapse {
  display: none;
}

.collapsetoggle:focus {
  background-color: red;

}

.item {
  min-width: 220px;

  margin: 15px;

  font-size: 0.8em;
  line-height: 1.3em;
  text-align: center;
  font-weight: 350;

  background-color: #cccccc;
  border-radius: 7px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 9px 4px rgba(125, 125, 125, 0.2);
  -moz-box-shadow: 0px 0px 9px 4px rgba(125, 125, 125, 0.2);
  box-shadow: 0px 0px 9px 4px rgba(125, 125, 125, 0.2);
  background-color: var(--block-background);
  text-align: left;
  padding: 20px;


  border: 1px solid var(--text-color-trans);
}


.item .Tblock {

  height: 210px;
  padding: 20px;
}


.item .Vblock {
  height: 150px;
  overflow: hidden;

}


.item .Vblock img {
  width: 400%;
  display: block;
  margin-top: -50%;

}

#begin .item .Vblock img {

  margin-top: 0px
}

.item .Vblock.b2 img {
  margin-left: -100%;
}

.item .Vblock.b3 img {
  margin-left: -200%;
}

.item .Vblock.b4 img {
  margin-left: -300%;
}

.itemcheck {
  display: block;
  float: left;
  position: relative;
  width: 1em;
  height: 1em;
  border: none;
  right: 0px;
  bottom: 0px;
  font-size: 0.5em;
  margin-right: 20px;

  -webkit-appearance: none;
  appearance: none;
}

.itemcheck::after {
  content: '✔';
  display: block;
  color: #ccc;
  float: left;
  width: 1em;
  height: 1em;
  border: 0.2em solid #ccc;
  border-radius: 5px;
  font-size: 1.5em;
  text-align: center;
  background-color: #ffffff;
  padding: none;
  padding: 0.2em;

}


.itemcheck:checked::after {
  /* ...existing styles */

  /*
  content:" ";
  transform-origin: bottom left;
  border: 0.2em solid #ffcc00;
border-radius: 5px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);


*/
  content: '✔';

  border: 0.2em solid #587c4d;
  color: #333;

}

/*toggle switch*/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.toggle {
  background-color: #14060a;
}

input:focus+.toggle {
  box-shadow: 0 0 1px #310000;
}

input:checked+.toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggle.round {
  border-radius: 34px;
}

.toggle.round:before {
  border-radius: 50%;
}

/*end slider*/





.item img {
  display: block;
  float: left;
  clear: both;
}


.item h2,
.item h4 {

  border-bottom: 1px solid var(--text-color-trans);
  padding-bottom: 20px;
}

.sliderbutton {
  display: inline-block;
  float: left;
  width: 2em;
  height: 2em;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 50px;
  position: absolute;
  top: 70%;
  font-size: 2em;
}

.sliderbutton.sleft {
  left: 0px;
  float: left;
  z-index: 20;
}

.sliderbutton.sright {
  right: 0px;
  float: right;
  z-index: 20;
}




.slideRight,
.slideLeft {
  opacity: 0.1;
}

.coce {
  display: block;
  float: left;
  width: 40%;
  margin-top: 20px;
}


.profile .profilepic {

  width: 3em;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  margin: 0 1em;
  -webkit-box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);

}

.profile h2 {
  display: flex;
  align-items: center;
  margin: 0 1em;
  gap: 0.5em;
}

.coce section {
  display: block;
  float: left;
  border: 1px solid var(--text-color-trans);
  margin: 5px;
  border-radius: 50px;
  width: 100%;
}

.coce section h2 {
  font-size: 0.7em;
  padding-top: 2em;

}

.coce section p {
  font-size: 0.6em;


}

.coce .profilepic {

  width: 18%;
  border-radius: 100%;
  margin: 20px;
  text-align: center;
  display: inline-block;
  -webkit-box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 6px 129px -15px rgba(0, 0, 0, 0.36);
  float: left;

}


.sfeerim {
  display: block;
  width: 30%;
  float: right;
  margin: 0.8em;


  -webkit-box-shadow: 0px 0px 11px 3px rgba(82, 76, 82, 0.29);
  -moz-box-shadow: 0px 0px 11px 3px rgba(82, 76, 82, 0.29);
  box-shadow: 0px 0px 11px 3px rgba(82, 76, 82, 0.29);


  border-radius: 10px;

}


.sicon {
  width: 1.5em;

}




/*collapsible*/

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: var(--background-color);
  color: var(--color);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.collapsibleContent {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: var(--second-block-background);
}

.checklist ul li {
  list-style: none;
  line-height: 1.5em;
}



.fadeimg {
  display: flex;
  float: right;

  width: 60%;
  height: calc(100% + 40px);
  background-color: var(--second-block-background);
  margin: -20px;


  -webkit-box-shadow: inset 142px 27px 69px -45px var(--block-background);
  -moz-box-shadow: inset 142px 27px 69px -45px var(--block-background);
  box-shadow: inset 142px 27px 69px -45px var(--block-background);
}

.fim1 {

  background-image: url(./img/webp/bank2.gif);
  background-size: cover;
  height: 100%;
  margin: 0px;

}

.burgericon {
  display: none;
  background-color: transparent;
  margin-bottom: 5px;
  z-index: 10000000000000000000;
  width: 2.5em;
  height: 2.5em;
}






/*collapser*/
.collapser {

  list-style: none;
  padding: 0;
}

.collapser li {
  border-bottom: 1px solid var(--theme-color);
}

.collapser li p {
  cursor: pointer;
  color: var(--theme-color);
  font-weight: bold;
}

.collapser li p:hover {
  color: var(--text-color)
}

.collapser li .answer.hidden {
  transform: scaleY(0);
  max-height: 0;
}


.collapser li .answer {
  margin-bottom: 1em;
  display: block;
  max-height: 10em;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.26s ease, max-height 0.2s ease;
}




/*collapser*/

/*media queries*/
.sfeerim2 {
  width: 100%;
}



h1 {
  font-size: 32px;
  text-align: center;
}

h2 {
  margin-top: 3em;
  font-size: 24px;
}

h4 {
  text-align: center;
}

.organigram-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 3em;
}

.organigram {
  border-radius: 5px;
  border: 3px solid var(--theme-color);
  width: 80%;

}

.img-container {
  display: flex;
  margin-top: 1em;
  flex-wrap: wrap;
  justify-content: space-between;
}

.img-container div,
.img-container img {
  height: 100%;
  width: 45%;
  border-radius: 5px;
  line-height: 1.5em;
  margin-bottom: 3em;
}

.img-container h2{
  margin-top: 0;
}

.training-container img {
  margin-top: 3em;
  border-radius: 5px;
}

.white-background {
  background-color: #fff;
}

/* .training-container__img {
  background-image: url('./img/GFX16341.jpg');
  background-position: right;
  background-size: cover;
}

.recruitment-container__img {
  background-image: url('./img/GFX16214.jpg');
  background-size: cover;
  background-position: center;

} */
/* 
.interview-container__img {
  background-image: url('./img/GFX16228.jpg');
  background-size: cover;
  background-position: center;
} */

.block-container {
  display: flex;
  justify-content: space-between;
}

.block-container section {
  width: 35%;
  padding: 1em 3em;
  background-color: var(--text-color);
  color: #fff;
  border-radius: 5px;

}
.block-container-schade section {
  padding: 1em 3em;
  background-color: var(--text-color);
  color: #fff;
  border-radius: 5px;

}
#praktisch .block-container section , .block-container-schade section {
  width: auto;
}

.block-container a , .block-container-schade a{
  color: #000000;
}

.block-container a:hover , .block-container-schade a:hover {
  color: #fff
}


.block-container section h2 , .block-container-schade section h2 {
  margin-top: 1em;
  text-align: center;
}

.extra-margin {
  margin-bottom: 2em;
}

.checklist-container__checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
}

/* Hide the default checkbox */
input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  border-radius: 5px;
  border: 1px solid var(--theme-color);
}

/* Specify the background color to be 
shown when hovering over checkbox */
.checklist-container__checkbox:hover input~.checkbox-custom {
  background-color: var(--theme-color);
}

/* Specify the background color to be 
shown when checkbox is active */
.checklist-container__checkbox input:active~.checkbox-custom,
.checklist-container__checkbox input:checked~.checkbox-custom {
  background-color: #ef7d00;
  border: 1px solid #ef7d00;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.checklist-container__checkbox input:checked~.checkbox-custom:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and  
showing only two border to make it look 
like a tickmark */
.checklist-container__checkbox .checkbox-custom:after {
  left: 6px;
  bottom: 4px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.no-padding {
  padding: 0;
}

.icon {
  text-align: center;
  font-size: 30px;
  padding: 5px;
  border: 1px solid white;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  margin: auto;
}

.icon i {
  padding: 0.5em 0;
  vertical-align: middle;
  display: inline-block;
}

#ziekte,
#timesheet {
  margin: 0.5em 0;
}

.admin-container img {
  margin: 0;
}

.personalised-block {
      width: 100%;
    margin: 0 auto; 
  }

.btn-logout {
  cursor: pointer;
  display: flex;
}

.btn-logout:hover {
  color: #ef7d00;
}
@media only screen and (max-width: 1300px) {

  .sfeerim {

    max-width: 80%;
    height: 100%;
  }
  .organigram {

    width: 90%;
    margin: 1em 0;
    height: auto;
  }


}

@media only screen and (max-width: 970px) {

  h1 {
    font-size: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h2 {
    font-size: 20px;
  }

  .sfeerim {

    width: 50%;
    display: block;
    float: left;
  }

  .slideRight,
  .slideLeft {
    display: none;

  }

  .fadeimg {

    display: none;
  }


  .main {
    display: block;
    float: left;
    width: 100%;
    font-size: 1.5em;
  }

  .coce {
    display: block;
    float: left;
    width: 100%;
  }

  .sfeerim {

    min-width: 100%;
    height: 100%;
  }

  .intro .introimg {
    width: 50%;
    margin-top: -200px;
    margin-right: 10px;
    margin-left: 10px;
  }


  .introText {
    width: 100%;

  }


  .burgericon {
    display: block;
    float: right;
    margin: 1em 1em 0;
    align-self: self-end;
    font-size: 26px;
    cursor: pointer;
  }


  #topnavigation {
    display: flex;
    flex-direction: column;
    position: absolute;
    float: left;
    top: 0px;
    text-align: right;
    width: 100%;
    margin-top: 0;
  }

  #topnavigation .nav-stepper {
    padding: 0px;
    overflow: hidden;
    text-align: right;
    flex-direction: column;
    background-color: #e0e0e0;

  }

  #topnavigation .nav-stepper__item:not(:last-child) {
    border-bottom: 2px solid var(--text-color);
  }

  .nav-stepper__title {
    color: var(--color);
  }

  .nav-stepper__title:hover {
    color: var(--theme-color);
  }

  .nav-stepper__item:not(:last-child):after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    height: 0px;
    width: 0;
  }

  #topnavigation .nav-stepper.visible {
    height: 100%;
  }

  #topnavigation .nav-stepper.notvisible {
    height: 0px;
  }

  #topnavigation .nav-stepper__circle {
    display: inline;
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
  .nav-stepper__item h3 {
    display: inline;
  }
  .topnav a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #toplogo {
    margin: 1em;
    display: flex;
    flex-direction: column;
  }

  #toplogo img {
    width: 10em;
  }

  #toplogo .introText {
        text-align: center;
    margin-top: 2em;
  }

  #toplogo .introText h1 {
    font-size: 24px;
    margin-bottom: 0;
  }

  #toplogo .introText p {
    margin-top: 1rem;
  }

  .burgericon img {
    height: 2em;
    display: block;
    margin-left: 0.5em;
    float: right;
  }

  .img-container {
    display: block;
  }

  .img-container div,
  .img-container img {
    margin: 1em 0;
    width: 100%;
    border-radius: 5px;
  }

  .admin-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
  }
  .admin-container img {
    margin: 0;
  }


  .person {
    display: block;
    position: fixed;
    z-index: 100000000000000000000;
    top: 15px;
    width: 2em;
    display: block;
    right: 130px;

  }

  .person :hover {
    background: transparent;
  }

  .person IMG {
    display: block;
    position: fixed;

    top: 15px;
    width: 2em;
    display: block;
    right: 130px;
  }


  .item {

    min-width: calc(100% - 70px);

    background-repeat: no-repeat;
    background-position: right;
    background-size: 5em;

  }

  .slider :last-child {

    min-width: calc(100% - 70px);
    background-image: none;


  }

  .item .Tblock {
    height: 200px;

  }

  .item .Vblock {
    height: 200px;
    margin-top: 30px;

  }

  .item {

    font-size: 1.1em;
  }

  .coce section h2 {
    font-size: 1.1em;
    padding: 1em;

  }

  .coce section p {
    font-size: 1em;
    padding: 1em;
  }

  .block-container {
    flex-direction: column;
  }

  .block-container section {
    width: auto;
    margin: 1em 0;
  }
  .organigram {

    width: 100%;
  }

}
svg.teams{
  width: 1.5rem;
  margin: 0rem 0.25rem;
  position: absolute;
}
.teams-label{
  margin-left: 2rem;
}